<template>
  <div class="page">
    <BreadCrumb />

    <div class="page-body">
      <QueryModule
        :query-module="queryModule"
        @queryRes="queryRes"
        @reset="reset"
      >
        <el-col :span="8">
          <el-date-picker
            v-model="queryModule.timeCond"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
          />
        </el-col>
        <el-col :span="8">
          <el-form-item label="名称">
            <el-input v-model="queryModule.projectName" />
          </el-form-item>
        </el-col>
      </QueryModule>

      <el-table
        v-loading="loading"
        :data="tableData"
        fit
        stripe
      >
        <el-table-column
          :index="(queryModule.page - 1) * pageSize + 1"
          label="序号"
          type="index"
        />
        <el-table-column label="项目名称">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              @click="toWorkList(scope.row)"
            >
              {{ scope.row.projectName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="标签"
          prop="nickname"
        />
        <el-table-column
          label="创建人"
          prop="createBy"
        />
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="140"
        />
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip
              class="item"
              content="导出"
              effect="light"
              placement="top-end"
            >
              <el-button
                circle
                size="mini"
                type="primary"
                @click="reportDialog(scope.row)"
              >
                <el-icon :size="15">
                  <i-download />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              content="导出评估报告"
              effect="light"
              placement="top-end"
            >
              <el-button
                circle
                size="mini"
                type="primary"
                @click="evaluationReport(scope.row)"
              >
                <el-icon :size="15">
                  <i-document />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="queryModule.page"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />

      <el-dialog
        v-model="dialogReport"
        title="报告导出"
      >
        <el-form :model="report">
          <el-form-item label="是否导出未运行成功的作业">
            <el-switch v-model="report.isShowNotSuc" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              type="primary"
              @click="exportReport()"
            >确 定</el-button>
             <el-button @click="dialogReport = false">取 消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import {mapGetters} from 'vuex'

export default {
	components: {
		BreadCrumb,
		QueryModule
	},
	data() {
		return {
			loading: true,
			tableData: '',
			total: 0,
			queryModule: {
				timeCond: null,
				createBy: '',
				projectName: '',
				page: 1
			},
			labelWidth: '120',
			dialogReport: false,
			report: {
				projectId: '',
				isShowNotSuc: false
			}
		}
	},
	computed: {
		...mapGetters(['pageSize', 'user'])
	},
	mounted: function () {
		const that = this

		that.queryModule.createBy = that.user.username
		that.projectList()
	},
	methods: {
		reset() {
			const that = this

			Object.keys(that.queryModule).forEach(key => (that.queryModule[key] = ''))
			that.queryModule.createBy = that.user.username
			that.queryModule['timeCond'] = null
			that.queryModule.page = 1
			that.projectList()
		},
		queryRes(data) {
			const that = this

			that.queryModule = data
			that.projectList()
		},
		projectList() {
			const that = this

			this.$axios
					.POST('/api/v1/core/project/all', {
						page: that.queryModule.page - 1,
						size: that.pageSize,
						timeCond: that.queryModule.timeCond,
						createBy:'',
						projectName: that.queryModule.projectName
					})
					.then(res => {
						if (res.code == 200) {
							that.loading = false
							that.tableData = res.data.content
							that.total = res.data.totalElements
						}
					})
		},

		handleCurrentChange(currentPage) {
			const that = this

			that.queryModule.page = currentPage
			that.projectList()
		},
		toWorkList(row) {
			this.$router.push({
				name: 'workExport',
				query: {projectId: row.projectId}
			})
		},
		reportDialog(row) {
			this.dialogReport = true
			this.report.projectId = row.projectId
		},
		exportReport() {
			const that = this

			that.$axios
					.DOWNLOAD(
							'/api/v1/core/project/exportJobReport/' + this.report.projectId,
							{
								isShowNotSuc: that.report.isShowNotSuc
							}
					)
					.then(res => {
						this.dialogReport = false
						const content = res

						that.$publics.downloadFile(content, '项目报告', 'xlsx')
					})
		},
		evaluationReport() {
			const that = this

			that.$axios
					.DOWNLOAD('/api/v1/core/project/exportJobAssessReport', null)
					.then(res => {
						const content = res

						that.$publics.downloadFile(content, '评估报告', 'pdf')
					})
		}
	}
}
</script>

<style></style>
